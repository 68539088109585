<template>
  <div>
    <div id="parallax" style="margin-top: 100px" class="py-5">
      <b-container fluid class="img-screen">
        <div
          class="d-flex flex-row justify-content-center align-items-center mr-5 my-4"
          id="head_container"
        >
          <img
            style="float:left"
            class="mr-5"
            src="@/assets/magnify_glass.webp"
            id="mag_glass"
          />
          <div class="header-1 mr-5">
            <h2>EASY, EFFICIENT LAW SEARCHING</h2>
          </div>
        </div>
        <div class="header-2">
          <p>
            ENKI helps
            <span style="color:orange">solicitors requisition searches</span>
            earlier, through a
            <span style="color:orange"
              >reliable<br />
              online portal</span
            >
            that means you are not waiting for searches on closing day.
          </p>
        </div>
        <div class="img d-grid" style="position:relative; justify-content:center; align-items:center;">
          <img
            alt="computer-screen"
            style="max-width:100%; height:auto;"
            src="@/assets/Laptop.webp"
          />

          <b-carousel
            id="carousel"
            :interval="2000"
            style="text-shadow: 1px 1px 2px #333; position:absolute; z-index:1;"
          >
            <b-carousel-slide :img-src="image1" > </b-carousel-slide>

            <b-carousel-slide :img-src="image2"> </b-carousel-slide>
          </b-carousel>

        </div>
        <div class="header-3 my-4">
          <p>
            The only place you will find a Law search that is fast and easy to
            use
          </p>
        </div>
        <b-button
          href="https://search.enki.ie/"
          size="md"
          class="my-2 my-sm-0 btn px-5"
          type="submit"
          ><i class="fas fa-search"></i> SEARCH</b-button
        >
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashPage",

  data() {
    return {
      image1: require("@/assets/computer-screenNEW.png"),
      image2: require("@/assets/computer-screenNEW2.png"),
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

#parallax {
  background-image: url("../assets/background.webp");

  min-height: 500px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#carousel {
  height: 280px;
  width: 454px;
  top: 40px;
  margin: auto;
  left: calc(50% - 227px);
}
.btn {
  background-color: #f92451;
  border: none;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 600;
}
.btn:hover {
  background-color: #faae26;
}
.img-responsive {
  position: absolute;
  right: 0;
}
@media screen and (max-width:630px) {
#carousel{
  width: 70vw;
  height: auto;
  left: calc(50% - 35vw)  !important;
  top: 10%;
}
.header-1 > h2{
  font-size: 1.5rem;
}
#mag_glass{
  height: 40px;
  margin-right: 1.5rem !important;

}
#head_container{
  margin-right: 0.5rem !important
}
}
@media screen and (max-width:400px) {
#carousel{
  width: 65vw;
  height: auto;
  left: calc(50% - 32.5vw)  !important;

}
.header-1 > h2{
  font-size: 1.25rem;
  width: 10rem;
}
#mag_glass{
  height: 30px;
  margin-right: 1.5rem !important;

}
#head_container{
  margin-right: 0.5rem !important
}
}
</style>
