<template>
        <b-container id="enki_pillars" class="mw-100">
            <b-row align-v="stretch">
                <b-col style="padding:2rem" md=4 class="d-flex flex-column justify-content-around" v-for="item in items" :key="item.id" :style="{backgroundColor:item.background, color:item.fontCol}" >
                    <img :src=item.img_src style="height:50px; " class="my-4 align-self-center">
                    <div style="font-size: 20px; font-weight:500;">{{item.title}}</div>
                    <div class="content my-4">{{item.content}}</div>
                </b-col>

            </b-row>
        </b-container>
</template>

<script>
export default {
    name: 'Statements',
    data:()=>{
        return {
            items:[
                {
                    id:1,
                    fontCol:'#fff',
                    img_src: require('../assets/star_Yellow.webp'),
                    background:'#463b35',
                    title: 'Our Company Mission',
                    content: 'ENKI aims to provide information products to professional services providers in an online, efficient and innovative manner.'
                },
                {
                    id:2,
                    img_src:require('../assets/ENKI_Logo.webp'),
                    background:'#ececec',
                    title: 'The ENKI Philosophy',
                    content: 'ENKI uses agile development processes to evolve and innovate quickly. We collaborate with our colleagues and clients throughout our product design, we are transparent and hungry for input.'
                },
                {
                    id:3,
                    img_src: require('../assets/Star_Brown.webp'),
                    background:'#faae26',
                    title: 'The ENKI Promise',
                    content: 'ENKI promises to deliver superior information services in real time and to constantly challenge the norms in the market through innovation.'
                }
            ]
        }
    }
}
</script>

<style scoped>
.content{
    font-size: 16px;
    line-height:1.3rem; 
    margin-inline:auto;
}

</style>