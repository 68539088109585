<template>
  <b-form
    id="contact_form"
    class="mx-auto"
    @submit.prevent="handleSubmit"
    style="color: white"
  >
    <div id="header-text" class="mb-5">
      <h3 class="mb-4">Contact Us</h3>
      <div style="font-size: 0.875rem">
        Get in touch today with any queries about ENKI We look forward to
        hearing from you.
      </div>
      <div class="mt-4 mb-2">
        <b>ENKI</b>, 3 Inns Court, Winetavern Street, Dublin 8, D08Y078
      </div>
      <div>
        <a
          href="mailto:info@enki.ie?subject=ENKI Website Inquiry"
          target="_self"
          style="color: #f9d145; text-decoration: none; font-weight: 500"
          >info@enki.ie</a
        >
        <span class="mx-2">|</span>
        <span
          >Tel:
          <a href="tel:+35314880990" style="text-decoration: none; color: white"
            >+353 1 4880990</a
          ></span
        >
      </div>
    </div>
    <b-overlay :show="loading" variant="secondary" blur="2rem" opacity="0.8">
      <b-container id="form-container" class="text-left w-50 text-white">
        <b-row>
          <b-col md="6">
            <b-form-group label="Full Name *" label-for="name_input">
              <b-form-input
                id="name_input"
                placeholder="E.g., Kat Jones"
                v-model="contactForm.name"
                required
                @blur="$v.contactForm.name.$touch()"
              >
              </b-form-input>
              <p
                v-if="
                  $v.contactForm.name.$invalid && $v.contactForm.name.$dirty
                "
                class="errors text-danger"
              >
                Name is required.
              </p>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Company" label-for="company_input">
              <b-form-input
                id="company_input"
                placeholder="Company"
                v-model="contactForm.company"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              label="Email *"
              label-for="email_input"
              type="email"
              required
            >
              <b-form-input
                id="email_input"
                placeholder="E.g., mail@example.com"
                required
                v-model="contactForm.email"
                @blur="$v.contactForm.email.$touch()"
              >
              </b-form-input>
              <span
                v-if="
                  $v.contactForm.email.$invalid && $v.contactForm.email.$dirty
                "
                class="errors text-danger"
                >Email is invalid.</span
              >
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Phone" label-for="num_input">
              <b-form-input
                id="num_input"
                placeholder="E.g., 123 456 7890"
                v-model="contactForm.phone"
                type="tel"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Message *" label-for="msg_input">
          <b-form-textarea
            id="msg_input"
            v-model="contactForm.msg"
            placeholder="Type your message here."
            rows="4"
            max-rows="10"
            @blur="$v.contactForm.msg.$touch()"
          ></b-form-textarea>
        </b-form-group>
        <div class="d-flex flex-row justify-content-end mt-4">
          <!-- <b-button id="reset" type="reset" variant="danger">Clear</b-button> -->
          <b-button
            :disabled="$v.contactForm.$invalid"
            id="submit"
            type="submit"
            >Submit
          </b-button>
        </div>
      </b-container>
    </b-overlay>
  </b-form>
</template>


<script>
import { required, minLength, email } from "vuelidate/lib/validators";
const axios = require("axios").default;
export default {
  name: "Contact",
  data: function() {
    return {
      contactForm: {
        name: null,
        company: null,
        email: null,
        phone: null,
        msg: null,
      },
      loading: false,
      msgSent: false,
      msgFailure: false,
    };
  },
  validations: {
    contactForm: {
      name: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email: email,
      },
      msg: {
        required,
      },
    },
  },
  methods: {
    handleSubmit: function() {
      if (!this.$v.contactForm.$invalid) {
        this.loading = true;
        axios
          
          /*.post("/cgi-bin/enki-contact-us.py",*/
          .post('https://ylx88dz347.execute-api.eu-west-1.amazonaws.com/prod',{
            Name: this.contactForm.name,
            Company: this.contactForm.company,
            Email: this.contactForm.email,
            Phone: this.contactForm.phone,
            Message: this.contactForm.msg,
          })
          .then((resp) => {
            this.loading = false;
            this.successToast();
            console.log("✉️ Message Sent", resp);
          })
          .catch((error) => {
            this.msgFailure = true;
            this.loading = false;
            this.errorToast();
            console.log("❌ Error,", error.response.status);
          });
        this.contactForm.name = "";
        this.contactForm.company = "";
        this.contactForm.email = "";
        this.contactForm.phone = "";
        this.contactForm.msg = "";
      } else {
        console.log("❌ Error, invalid");
      }
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    /*     handleClear: function (evt) {
      evt.preventDefault();
      this.contactForm.name = "";
      this.contactForm.company = "";
      this.contactForm.email = "";
      this.contactForm.phone = "";
      this.contactForm.msg = "";
    }, */
    successToast() {
      this.$bvToast.toast(
        "Thanks for getting in touch, a member of our team will be in touch shortly.",
        {
          title: "Message Sent Successfully!",
          variant: "success",
          solid: true,
        }
      );
    },
    errorToast() {
      this.$bvToast.toast(
        "Please try sending your message again, an error has been logged in our system. Failing this, please try another form of contact.",
        {
          title: "An Error Occurred!",
          variant: "danger",
          solid: true,
        }
      );
    },
  },
};
</script>

<style scoped>
#contact_form {
  background: #98a4b4;
  padding-block: 2em;
}
#name_input,
#company_input,
#email_input,
#num_input,
#msg_input {
  background: transparent;
  border-color: white;
  color: white;
  height: 3rem;
}
#name_input::placeholder,
#company_input::placeholder,
#email_input::placeholder,
#num_input::placeholder,
#msg_input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
#submit {
  background: #faae26;
  border: 0;
  justify-self: flex-end;
  padding: 0.75rem 2rem;
  transition: all 0.5s;
}
#submit:disabled {
  background: #98a4b4;
  filter: brightness(90%);
  cursor: not-allowed;
}
#submit:hover:not(:disabled) {
  filter: brightness(105%);
  box-shadow: 0px 5px 5px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 5px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px -1px rgba(0, 0, 0, 0.1);
}
.errors {
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

@media only screen and (min-width: 375px) and (max-width: 764px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
#form-container{
  width: 75% !important;
}
#header-text{
  width: 75%;
  margin: auto;
}
}
</style>
