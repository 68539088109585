<template>
  <b-carousel id="carousel" :interval="4000" controls indicators>
    <b-container fluid>
      <p class="h3 mt-4 mb-4" style="color:white;">Testimonials</p>
    </b-container>
    <b-carousel-slide id="slides" v-for="page in pages" :key="page.slide">
      <b-container id="slideContent" sm="auto">
        <b-row>
          <p class="mx-auto" style="font-style: italic;">{{ page.content }}</p>
        </b-row>
        <b-row>
          <p class="mt-2 mx-auto">{{ page.client }}</p>
        </b-row>
      </b-container>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  name: "Testimonials",
  data: () => {
    return {
      pages: [
      
        {
          slide: 1,
          content:
            '"I wanted to send an email to thank you and your team who are extremely helpful and go above and beyond to assist me and this firm.'
            + ' How your Company operates not only provides an amazing service but the addition of the prompt responses and helpfulness of your staff is what makes for a truly outstanding service.'
            + ' I am highly recommending your company to all my colleagues and just wanted to say a genuine thank you! "',
          client: "Sinéad Mitchell, Newman Doyle LLP Solicitors, Dublin",
        },
        {
          slide: 2,
          content:
            '"As a result of signing up to ENKI we have been able to pass on cost savings to our clients. Submitting searches is so straightforward and the instant results have saved the firm a considerable amount of time. I would not hesitate in recommending the service to other firms."',
          client: "Anne Kelly , Paul Kelly & Company, Leixlip",
        },
        {
          slide: 3,
          content:
            '"ENKI’s free update and monitoring service is exactly what I need to ensure I have all the information available when offering conveyance searches to my clients and that there are no more closing day surprises. The team are extremely helpful and knowledgeable about conveyancing."',
          client: "Canice Walsh, Murphy Ramsay Walsh, Tralee",
        },
        {
          slide: 4,
          content:
            '"ENKI’s ability to provide instant Registry of Deeds as well as Land Registry closing searches is a huge advantage. Having access to my searches through email or online makes sharing closing day searches straightforward and the team is always on hand to help if I have any queries."',
          client: "Paul Brennan, Solicitor, Mayo",
        },
        {
          slide: 5,
          content:
            '"The ENKI service is innovative and any conveyance solicitor should be using it. The search submission is so straightforward to complete and the option to access the service at any time is of huge convenience. The option for free pre-contract searches and the instant results make closing day panic a thing of the past!"',
          client: "Emmet O'Gorman, O'Gorman Law, Wexford",
        },
      ],
    };
  },
};
</script>

<style scoped>
#carousel {
  background: #faae26;
  color: #000;
}
#slides {
  height: 12rem;
}
#slideContent {
  width: 75%;
  text-align: center;
}
#slideContent > p {
  margin-block: 2rem;
}


@media only screen and (max-width: 800px) {
  #slides {
    height: 15rem;
  }
  #slideContent {
    width: 100% !important;
  }
}

@media only screen and (max-width: 525px) {
    #slides{
        height: 20rem;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  #slides {
    height: 28vh;
  }
}
</style>
