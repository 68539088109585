<template>
    <div id="faqList" role="tabslist" class="accordian m-auto">
        <div class="d-flex flex-row mb-2 mr-sm-2 mb-sm-2 " style="min-height: 80px">
            <p id="head" v-show="!searchActive" class="h3 my-4 flex-grow-1">Frequently Asked Questions</p>
            <b-input-group v-show="searchActive" class="align-self-center">
                <b-form-input  
                id="faqSearch"
                :value= "null"
                placeholder="Looking for something?"
                v-model="search"
                ></b-form-input>
            </b-input-group>
            <b-button variant='transparent' v-b-toggle @click="searchActive = !searchActive" class="h-50 align-self-center">
                <b-icon-search v-if="!searchActive"></b-icon-search>
                <b-icon-x-circle v-else></b-icon-x-circle>

            </b-button>
        </div>
        <div class="text-left text-muted small ml-2" v-if="filteredQueries.length && search != '' && searchActive">Showing results for: {{ search }}</div>
        <div class="text-left text-danger small ml-2" v-if="!filteredQueries.length && search != '' && searchActive">Sorry, we could not find any results to match your search criteria.</div>
        <b-card no-body class="mb-1" v-for="(query, index) in filteredQueries" :key="index" border-variant='white'>
            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:transparent;">
                <b-button squared variant='transparent' class="text-left py-3 d-flex justify-content-between text-muted" block v-b-toggle="'accordian' + query.index">
                    {{ query.question }}
                   <b-icon-chevron-up v-if="query.isActive"></b-icon-chevron-up>
                   <b-icon-chevron-down v-else></b-icon-chevron-down>   
                </b-button>
            
            </b-card-header>
            <b-collapse class="text-left" :id="'accordian' + query.index" :accordion="'accordian' + query.index" role="tabpanel" v-model="query.isActive">
                <b-card-body>
                    <b-card-text>{{ query.answer }}</b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    data:()=>{
        return{
            search:"",
            searchActive: false,
            queries: [
                {
                    index:1,
                    question: 'What is ENKI?',
                    answer:'ENKI is a fully bonded law searching service that allows solicitors requisition searches online and in real-time. The ENKI platform offers total control over the closing day schedule and eliminates any last minute search surprises.'
                },
                {
                    index:2,
                    question: 'What type of searches does ENKI offer?',
                    answer:'ENKI offers online Registered and Unregistered property, CRO, Central Office, Proceedings, Lis Pendens, Judgments, Bankruptcy, Insolvency, Sheriff and Receiver of fines searches.'
                },
                {   
                    index:3,
                    question: 'Does ENKI offer planning searches?',
                    answer:'ENKI offers 3 types of planning searches, Residential, Commercial and Commercial Pro. ENKI carries out up to 34 checks when carrying out planning searches. Planning searches can also be prescribed to meet client requirements.'
                },
                {
                    index:4,
                    question: 'What if I need a special type of search?',
                    answer:'ENKI is a full service legal services provider. We pride ourselves on customer service and we endeavour to deliver any type of search to our customers expectations.'
                },
                {
                    index:5,
                    question: 'What are ENKI turnaround times?',
                    answer:'The ENKI platform allows customers to requisition searches at any time during a transaction. Once searches are ordered we will return an instant set of searches that are monitored daily. When your desired closing date arrives the searches will automatically be updated and sent to you.'
                },
                {
                    index:6,
                    question: 'What if my closing date changes?',
                    answer:'Transactions can get delayed so The ENKI platform allows users to change the closing date as often as they wish.'
                },
                {
                    index:7,
                    question: 'Why should I use ENKI?',
                    answer:'The ENKI platform is designed by an experienced team from the legal services industry. Our agile development strategy and technical experience will ensure that the legal searches never get in the way of a completion. The platform is designed to give the user control of the timelines and flexibility around the search process.'
                },
                {
                    index:8,
                    question: 'What does ENKI cost?',
                    answer:'ENKI is a transactional service and you pay for each search individually. There are no additional charges for monitoring or the final search refresh. Our helpful search calculator can be used to establish costs early on so there are no last minute surprises.'
                },
            ]
        }
    }, methods: {

    }, computed:
    {
        filteredQueries:function(){
            return this.queries.filter((query)=>{
                if (this.query != ''){
                    return query.question.toLowerCase().match(this.search.toLowerCase())
                }
                return this.queries
            })
        }
    }, watch:{
        searchActive(val){
            if (val === false) this.search = ''
        }
    }

}

</script>

<style scoped>
#faqList{
    width:60%;
    min-height: 320px;
}
@media only screen and (max-device-width: 375px) and (orientation: portrait){
    #faqList{
        width: 100%;
        padding-inline: 0.5rem;
    }
    #faqList >>> #head{
        font-size: 1.25rem;
        text-align: center;
        padding-top: 1.5%;
    }
}

</style>
