<template>
    <div class="px-4">
        <!--<b-container class="bv-row">-->
          <b-row style="position:relative; bottom:10px; top:2px;" class="feature-items mx-2 mb-4 mt-0 pt-5">
            <b-col class="bv-row">
              <img alt="computer-screen" src="@/assets/PRAI Search.webp">
              <h3>PRAI Search</h3>
              <p>ENKI registered and unregistered property searches are available in real-time. Our searches include applications pending searches and our report is clear when no pending applications exist.</p>
            </b-col>
            <b-col class="bv-row">
              <img alt="computer-screen" src="@/assets/Planning Search.webp">
              <h3>Planning Search</h3>
              <p>ENKI planning searches incorporated 3 types of search results.  Residential Searches, Commercial Searches and Commercial Pro. Our searchers carry out up to 34 checks per search.</p>
            </b-col>
            <b-col class="bv-row">
              <img alt="computer-screen" src="@/assets/Features_Company.webp">
              <h3>Companies Office</h3>
              <p>ENKI companies office searches are delivered in real-time directly from the CRO and under license. Additional companies can be added to the requisition and petitions are searched as standard</p>
            </b-col>
            <b-col class="bv-row">
              <img alt="computer-screen" src="@/assets/Part Features.webp">
              <h3>Party Searches</h3>
              <p>We carry out party searches against records held in the Insolvency office, Bankruptcy office, High Court Central Office. It's easy to add proceedings searches and all elements are monitored.</p>
            </b-col>
            <b-col class="bv-row">
              <img alt="computer-screen" src="@/assets/receiveroffines.webp">
              <h3>Receiver of Fines</h3>
              <p>Receiver of fines searches are requisitioned through a separate process which ensures that only the correct and required searches are ordered for your property transaction.</p>
            </b-col>
          </b-row>
      <!--</b-container>-->
    </div>
</template>

<script>
    export default {
        name:"Features"
    }
</script>

<style lang="scss" scoped>
.bv-row{
  padding: 0 3rem;
}
.bv-row > h3{
  font-size: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.bv-row > p{
  font-size: 13px;
}
</style>