<template>
  <div id="app">
    <b-navbar
      variant="faded"
      type="light"
      class="custom-navbar navbar-light bg-light"
      toggleable="lg"
      id="navigation"
    >
      <b-container class="w-75">
        <b-container
          class="d-flex flex-column w-25 m-0"
          style=" align-items: flex-start; font-size: 14px;"
        >
          <b-row no-gutters>
            <b-navbar-brand href="#splash">
              <img
                alt="ENKI Logo"
                class="d-inline-block align-top"
                src="@/assets/ENKI_Logo.webp"
                height="45px"
              />
            </b-navbar-brand>
          </b-row>
          <b-row no-gutters>
            <div class="contact-call">
              Call Us Today on
              <span style="color:orange"><strong>01 488 0990</strong></span>
            </div>
          </b-row>
        </b-container>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto" style="font-size: 14px; font-weight:800">
            <b-button
              id="btn"
              style="font-size:16px; font-weight: 600; padding-inline: 2rem;"
              size="md"
              class="my-2 my-sm-0 btn"
              type="submit"
              href="https://search.enki.ie/"
              
              ><i class="fas fa-search"></i> SEARCH</b-button
            >
            <b-nav-item class="nav-item" href="#features"><i class="fas fa-list"></i> FEATURES</b-nav-item>
            <b-nav-item class="nav-item" href="#platform"><i class="fas fa-desktop"></i> PLATFORM</b-nav-item>
            <b-nav-item class="nav-item" href="#testimonials"><i class="fas fa-comment"></i> TESTIMONIALS</b-nav-item>
            <!-- <b-nav-item class="nav-item" href="#resources"><i class="fas fa-book"></i> RESOURCES</b-nav-item> -->
            <b-nav-item class="nav-item" href="#faq"><i class="fas fa-question"></i> FAQs</b-nav-item>
            <b-nav-item class="nav-item" href="#contact"><i class="fas fa-envelope-open-text"></i> CONTACT</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <div id="splash">
      <SplashPage />
    </div>
    <div id="features">
      <Features />
    </div>
    <div id="platform">
      <Platform />
    </div>
    <div id="testimonials">
      <Testimonials />
    </div>
    <div id="statements">
      <Statements />
    </div>
    <div id="faq">
      <FAQ />
    </div>
    <div id="contact" class="nav_anchor">
      <Contact />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import Platform from "./components/Platform";
import Testimonials from "./components/Testimonials";
import Statements from "./components/Statements";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import SplashPage from "./components/SplashPage";
import Features from "./components/Features";

export default {
  name: "App",
  components: {
    Platform,
    Statements,
    Testimonials,
    FAQ,
    Contact,
    AppFooter,
    SplashPage,
    Features,
  },
};
</script>

<style>
html {
  scroll-padding-top: 100px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
#nav-collapse{
  background: #fff;
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
}
#navigation {
  height: 100px;
  position: fixed;
  z-index: 999;
  -webkit-box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
a.b-nav {
  color: black;
}
.header {
  font-family: Arial, Helvetica, sans-serif;
}
#btn {
  background-color: #f92451;
  border: none;
  font-family: "Open Sans Condensed", sans-serif;
}
#btn:hover {
  background-color: #faae26;
}
.nav-item {
  color: black;
  text-decoration: none !important;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 0.75rem;
}
.contact-call {
  text-align: right;
}
a.nav-link {
  text-decoration: none;
  color: black;
  font-family: "Open Sans Condensed", sans-serif;
  transition: 0.3s;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #faae26 !important;
}

nav.navbar.custom-navbar.navbar-light.bg-light.navbar-light.bg-faded.navbar-expand {
  position: fixed;
  width: 100%;
}
@media screen and (max-width: 1260px) {
  .contact-call{
    display: none;
  }
}
</style>
