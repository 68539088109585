<template>
  <b-container :style="{background: '#9aa6b6', backgroundImage: 'url(' +bgImage + ')'}" class="p-5" fluid>
    <b-container
      id="platform-container"
      style="text-align: left; overflow: hidden;"
      class="p-4"
    >
      <b-row class="mt-3 mb-5">
        <h3 class="m-auto" style="color: #fff">THE ENKI PLATFORM</h3>
      </b-row>
      <b-row align-v="stretch" id="statements">
        <b-col
          style="padding: 2rem; color: #fff;"
          lg="3"
          class="d-flex flex-column"
          v-for="(points, index) in timeline"
          :key="index"
        >
          <div id="container" class="mb-2">
            <div :id="'line' + points.id"><div id="dot"></div></div>
          </div>
          <p> 
            {{ points.point }}
          </p>
        </b-col>
      </b-row>

      <!-- Bottom Six Cards -->
      <b-row class="m-auto">
        <b-col md="6" lg="4" v-for="(card, index) in cards" :key="index">
          <!-- Actual Card Style -->
          <b-card
            class="overflow-hidden my-2 mx-1"
            style="font-size: 0.75rem; background: #d6dbe2; border-radius:0.5rem"
          >
            <b-card-body class="row py-0 px-1">
              <b-col id="card-img-col" lg="3" md="4" sm="2">
                <b-card-img id="card-img" :src="card.img" start height="60px">
                </b-card-img>
              </b-col>
              <b-col id="card-text" lg="9" md="8" sm="10" class="m-auto px-0">
                <b-card-text class="ml-4">
                  {{ card.statement }}
                </b-card-text>
              </b-col>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Platform",
  data: () => {
    return {
      timeline: [
        {
          id: 1,
          point:
            "ENKI is an online conveyance service that allows solicitors requisition searches at the contract signing phase of the conveyance process.",
        },
        {
          id: 2,
          point:
            "The full closing search is downloaded at this time and the planning search is ordered. The search results are then monitored from this time until closing.",
        },
        {
          id: 3,
          point:
            "The conveyance solicitor is informed of any changes to the search results as they arise. The search is finally refreshed on the date of the closing.",
        },
        {
          id: 4,
          point:
            "This process will allow solicitors peace of mind and ensure that there are no surprises for either the solicitor or the purchaser on the closing date.",
        },
      ],
      bgImage: require("../assets/background.webp"),
      cards: [
        {
          img: require("../assets/svg/gavel.svg"),
          statement: "Innovative online legal searches",
        },
        {
          img: require("../assets/svg/house.svg"),
          statement:
            "Suitable for Property, Leasing and Corporate transactions",
        },
        {
          img: require("../assets/svg/document.svg"),
          statement:
            "Searches can be requisitioned at contract signing and are monitored until closing",
        },
        {
          img: require("../assets/svg/protect.svg"),
          statement: "All searches are bonded",
        },
        {
          img: require("../assets/svg/euro.svg"),
          statement: "Pricing is transparent and consistent",
        },
        {
          img: require("../assets/svg/sheriff.svg"),
          statement: "Includes sheriff and planning searches",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
#container {
  align-content: center;
}
#dot {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  z-index: 1;
  position: absolute;
  top: -0.5rem;
}
#line1,
#line2,
#line3 {
  width: 100%;
  height: 0.15rem;
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 0;
  margin-block: 0.5rem;
}
#line4 {
  width: 75%;
  height: 0.15rem;
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 0;
  margin-block: 0.5rem;
}

@media screen and (max-width: 992px) {
  #dot {
    display: none;
  }
  #line1,
  #line2,
  #line3,
  #line4 {
    width: 85%;
  }
  #platform-container {
    text-align: center !important;
  }
  #card-text > p.card-text{
    margin-top: 1rem;
    margin-left: 0  !important;
    padding: 0.25rem;
}
}

@media only screen and (min-device-width: 488px) and (max-device-width: 524px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  #platform-container {
    text-align: center !important;
  }
  #card-img {
    height: 60px;
  }
  #card-img-col {
    width: 25%;
  }
  #card-text {
    width: 70%;
  }
  #dot {
    display: none;
  }
  #line1,
  #line2,
  #line3,
  #line4 {
    width: 85%;
  }
}
</style>
